import { DefaultTheme } from "styled-components";

const baseTheme: DefaultTheme = {
  colors: {
    black: "#000000",
    white: "#fff",
    red: "#e00033",
    blue: "#3bbdf5",
    darkBlue: "#12598E",
    lightGrey: "#d0d0d0",
    grey: "#999",
    darkGrey: "#393C47",
    google: "#dd4b39",
    facebook: "#4c69ba",
    background: "#0d111e",
    gold: "#F0C72F",
    green: "#52FF00",

    bonus: "#FBBB33",
    compete: "#14C791",
    occupied: "#FF0047",
    waiting: "#57BDF5",
    error: "#FF0047",
    unavailable: "#E5E5E5",
    available: "#14C77C",
    premium: "#FFFFFF",
    whiteText: "#FFFFFF",
    blackBackground: "#000000",
    blackBackgroundOpacity40: "rgb(0, 0, 0, 0.4)",
    disabled: "#43464B",
    whiteOpacity25: "rgb(255, 255, 255, 0.25)",
    greyText: "#D9D9D9",
    mainBackground: "#0D111E",
    menuBackground: "#091022",
    buttonSecondary: "#48516B",
    training: "#D4815D",
    duel: "#3DC3B6",
    tournament: "#34A3D3",
    createTournament: "#73BCDB",
    multiplayer: "#375EAE",
    championship: "#6755D6",
    divider: "#1E2746",
    app: "#3BBDF5",
  },
  fonts: {
    mobile: {
      h1: "900 20px/20px Orbitron, sans-serif",
      h2: "14px/16px Roboto-Bold, sans-serif",
      h3: "10px/12px Roboto-Light, sans-serif",
      button: "10px/12px Roboto-Medium, sans-serif",
      menuText: "8px/9px Roboto-Regular, sans-serif",
      menuTextActive: "8px/9px Roboto-Medium, sans-serif",
      formLabel: "12px/14px Roboto-Medium, sans-serif",
      formInput: "12px/14px Roboto-Regular, sans-serif",
      popupH1: "900 18px/18px Orbitron, sans-serif",
      timeCounter: "500 18px/22px Orbitron, sans-serif",
      rank: "700 8px/10px Orbitron, sans-serif",
      vs: "600 26px/20px Orbitron, sans-serif",
      topTitle: "900 14px/20px Orbitron, sans-serif",
    },
    desktop: {
      h1: "900 30px/30px Orbitron, sans-serif",
      h2: "24px/16px Roboto-Bold, sans-serif",
      h3: "10px/12px Roboto-Light, sans-serif",
      button: "10px/12px Roboto-Medium, sans-serif",
      menuText: "8px/9px Roboto-Regular, sans-serif",
      menuTextActive: "8px/9px Roboto-Medium, sans-serif",
      formLabel: "12px/14px Roboto-Medium, sans-serif",
      formInput: "12px/14px Roboto-Regular, sans-serif",
      popupH1: "900 24px/24px Orbitron, sans-serif",
      timeCounter: "500 18px/22px Orbitron, sans-serif",
      rank: "700 8px/10px Orbitron, sans-serif",
      vs: "600 26px/20px Orbitron, sans-serif",
      topTitle: "900 14px/20px Orbitron, sans-serif",
    },
    global: {
      text8light: "8px/10px Roboto-Light, sans-serif",
      text10: "10px/12px Roboto-Regular, sans-serif",
      text12: "12px/14px Roboto-Regular, sans-serif",
    },
  },
  fontFamilies: {
    poppins: "Poppins",
    robotoThin: "Roboto-Thin", // 100
    robotoLight: "Roboto-Light", // 300
    roboto: "Roboto-Regular", // 400
    robotoMedium: "Roboto-Medium", // 500
    robotoBold: "Roboto-Bold", // 700
    molot: "Molot",
    ohnow: "OhNow",
  },
  fontSizes: {
    breadcrumb: "14px",
    p: "13px",
    buttonMobile: "10px",
    buttonDesktop: "12px",
    body: "14px",
    caption: "12px",
    caption2: "10px",
    h0: "40px",
    h1: "35px",
    h2: "30px",
    h3: "25px",
    h4: "20px",
    h5: "18px",
    h6: "16px",

    h1Mobile: "20px",
    h2Mobile: "14px",
    h3Mobile: "10px",
    text10px: "10px",
    text12px: "12px",
    text8pxLight: "8px",
    btnTextMobile: "10px",
    menuTextMobile: "8px",
    formLabelMobile: "12px",
    formLabelDesktop: "14px",
    formInputMobile: "12px",
    formInputDesktop: "14px",
    formError: "10px",
    notification: "12px",
    accordionMobile: "10px",
    accordionDesktop: "12px",
  },
  zIndex: {
    popularGameButtonHover: 2,
    modal: 10,
    rankRangeInputValues: 2,
  },
  modalWidths: {
    sm: "20vw !important",
    md: "40vw !important",
    lg: "60vw !important",
    xl: "80vw !important",
    full: "100vw !important",
  },
  modalHeights: {
    sm: "20vh !important",
    md: "40vh !important",
    lg: "60vh !important",
    xl: "80vh !important",
    full: "100vh !important",
  },
  borderImages: {
    white: "linear-gradient(to left, #3d4253, #ffffff, #3d4253)",
    red: "linear-gradient(to left, rgba(255, 0, 0, 0.25), rgba(255, 0, 0, 1), rgba(255, 0, 0, 0.25))",
    grey: "linear-gradient(to left, #3d4253, #999, #3d4253)",
    blue: "linear-gradient(to bottom, #3bbdf5, #000000)",
  },
};

export default baseTheme;
