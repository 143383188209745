import { useEffect, useState } from "react";

import { IObservable } from "../../storage/storage.types";

export default function useStorage<T>(storage: IObservable<T>): T {
  const [value, setValue] = useState(storage.get);

  useEffect(() => {
    storage.subscribe(setValue);

    return () => storage.unsubscribe(setValue);
  }, [storage]);

  return value;
}
