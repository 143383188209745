import tokenParser from "../../helpers/token-parser";
import { AuthData, AuthStorageInterface } from "../../providers/authProvider/AuthProvider.types";

import LocalStorage from "../LocalStorage";

export class AuthLocalStorage extends LocalStorage<AuthData> implements AuthStorageInterface {
  setTokens = (accessToken: string | null, fullAccess: boolean, refreshToken?: string): void => {
    if (accessToken === null) {
      this.set(null);
      return;
    }

    const tokenPayload = tokenParser(accessToken);

    this.set({
      accessToken,
      refreshToken,
      tokenPayload,
      fullAccess,
    });
  };
}

export const authStorage = new AuthLocalStorage("dod:auth");
